<template>
  <v-col v-if="!loading" cols="12" :md="size === 2 ? 6 : size === 2 ? 6 : size === 3 ? 4 : size === 4 ? 3 : undefined"
   >
    <v-hover v-slot="{ hover }">
      <base-card data-aos="zoom-in" color="grey lighten-1" dark>
        <v-img contain :src="baseImageUrl + value.image_name" :lazy-src="`https://picsum.photos/10/6?image=15`"
          :gradient="'rgba(0, 0, 0, .20),rgba(0, 0, 0, .20)'" :class="{
            'img-default-size': true,
            'img-scale-size': hover,
          }" class="transition-swing">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
          <v-row class="fill-height text-right ma-0">
            <v-col cols="12">
              <v-chip label class="mx-0 mb-2" color="grey darken-3" text-color="white" small>
                {{ value.tag.name }}
              </v-chip>

              <!-- <h3 class="title font-weight-bold mb-2">
                  {{ post.title }}
                </h3> -->

              <div class="caption">
                <!-- {{ post.author }}
              <br> -->
                {{ value.date | moment("dddd, MMMM Do YYYY") }}
              </div>
            </v-col>

            <v-col align-self="end">
                <v-chip
                  class="ma-0"
                  color="primary"
                  label
                  small
                  @click.stop=""
                  :href="'post/' + value.id"
                  target="_blank"
                >
                التفاصيل
                </v-chip>
              </v-col>
          </v-row>
        </v-img>
      </base-card>
    </v-hover>
  </v-col>
</template>
<script>
// Utilities
import {
  mapGetters,
} from 'vuex'
import { BASE_IMAGE_URL } from "@/constants/index";
export default {
  name: 'PostCard',
  data: () => ({
    baseImageUrl: BASE_IMAGE_URL,
  }),
  props: {
    size: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['loading']),
  },
}
</script>
  
<style >
.img-default-size {
  transition: .3s ease-in-out;

}

.img-scale-size {
  transition: .3s ease-in-out;
  transform: scale(1.05);
  ;
}
</style>
  